import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Row, Col } from 'rsuite';
import { Sidebar } from '../../../../components/Sidebar';
import { StyledContainer, DashboardInner, Detail, Date, Id, Number, Status, FakturaNumber, Typography, Filter, FilterTitle, FilterTable } from './styled';

export const FakturaDetail = () => {

  const { id } = useParams();

  return (
    <div>
      <StyledContainer>
        <Sidebar />
        <DashboardInner>
          <Filter>
            <FilterTitle># {id}</FilterTitle>
            <FilterTable>
              <Grid fluid>
                <Row gutter={2}>
                  <Col xs="4" style={{ margin: '10px 0 0' }}>
                    <Row>
                      <Typography size="18px" weight="700" style={{ marginBottom: "20px" }}>Číslo FAV</Typography>
                    </Row>
                    <Row>
                      <Id>210010</Id>
                    </Row>
                    <Row>
                      <Id>210009</Id>
                    </Row>
                    <Row>
                      <Id>210008</Id>
                    </Row>
                    <Row>
                      <Id>210007</Id>
                    </Row>
                  </Col>
                  <Col xs="8">
                    <Row>
                      <Detail>
                        <FakturaNumber>Číslo faktury {id}</FakturaNumber>
                        <Col xs="12">
                          <Row>
                            <Col>
                              <Typography size="18px" weight="700">Dodavatel:</Typography>
                            </Col>
                            <Col>
                              <Typography size="16px" weight="700">Firma A s.r.o</Typography>
                            </Col>
                          </Row>
                          <Row style={{ margin: '20px 0 0' }}>
                            <Col>
                              <Typography size="18px" weight="700">Lorem ipsum:</Typography>
                            </Col>
                            <Col>
                              <Typography size="16px" weight="700">Lorem A s.r.o</Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12">
                          <Row>
                            <Col>
                              <Typography size="18px" weight="700">Odeberatel:</Typography>
                            </Col>
                            <Col>
                              <Typography size="16px" weight="700">Firma B s.r.o</Typography>
                            </Col>
                          </Row>
                          <Row style={{ margin: '20px 0 0' }}>
                            <Col>
                              <Typography size="18px" weight="700">Lorem ipsum:</Typography>
                            </Col>
                            <Col>
                              <Typography size="16px" weight="700">Lorem B s.r.o</Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Detail>
                    </Row>
                  </Col>
                  <Col xs="8" style={{ margin: '100px 0 0 30px' }}>
                    <Number>Číslo ERF {id}</Number>
                    <Status>Zpracováno</Status>
                    <Date>Přijato 31.1.2021</Date>
                  </Col>
                </Row>
              </Grid>
            </FilterTable>
          </Filter>
        </DashboardInner>
      </StyledContainer >
    </div>
  )
}
