// Routes
export const DASHBOARD = '/';
export const DOKLADY = '/doklady';
export const FAKTURY = '/faktury';

export const FAKTURY_VYDANE = `${FAKTURY}/faktury-vydane`;
export const FAKTURY_VYDANE_DETAIL = `${FAKTURY}/faktury-vydane/:id`;
export const FAKTURY_VYDANE_RADNE = `${FAKTURY_VYDANE}/faktury-vydane-radne`;
export const FAKTURY_VYDANE_ZALOHOVE = `${FAKTURY_VYDANE}/faktury-vydane-zalohove`;
export const FAKTURY_VYDANE_ODD = `${FAKTURY_VYDANE}/faktury-vydane-odd`;
export const FAKTURY_VYDANE_DANOVE_DOKLADY = `${FAKTURY_VYDANE}/faktury-vydane-danove-doklady`;

export const FAKTURY_PRIJATE = `${FAKTURY}/faktury-prijate`;
export const FAKTURY_PRIJATE_RADNE = `${FAKTURY_PRIJATE}/faktury-prijate-radne`;
export const FAKTURY_PRIJATE_ZALOHOVE = `${FAKTURY_PRIJATE}/faktury-prijate-zalohove`;
export const FAKTURY_PRIJATE_ODD = `${FAKTURY_PRIJATE}/faktury-prijate-odd`;
export const FAKTURY_PRIJATE_DANOVE_DOKLADY = `${FAKTURY_PRIJATE}/faktury-prijate-danove-doklady`;

export const PLATBY_KARTOU = '/platby-kartou';

export const POKLADNI_DOKLADY = '/pokladni-doklady';
export const POKLADNI_DOKLADY_POKLADNA_PRIJEM = `${POKLADNI_DOKLADY}/pokladna-prijem`;
export const POKLADNI_DOKLADY_POKLADNA_VYDEJ = `${POKLADNI_DOKLADY}/pokladna-vydej`;

export const BANKOVNI_VYPISY = '/bankovni-vypisy';

export const OSTATNI = '/ostatni';
export const OSTATNI_ZAVAZKY = `${OSTATNI}-zavazky`;
export const OSTATNI_POHLEDAVKY = `${OSTATNI}-pohledavky`;

export const INTERNI_DOKLADY = '/interni-doklady';

export const PRIZNANI = '/priznani';

export const SMLOUVY = '/smlouvy';
