import React from 'react';
import { Link } from 'react-router-dom';
import { Sidenav, Icon, Nav, Dropdown } from 'rsuite';
import { StyledSidebar } from './styled';


const Sidebar = () => {

  return (
    <StyledSidebar>
      <div style={{ width: 250 }}>
        <Sidenav defaultOpenKeys={['2', '3', '4']}>
          <Sidenav.Body>
            <Nav>
              <Nav.Item componentClass="span" eventKey="1" icon={<Icon icon="file-text-o" />}>
                <Link to="/doklady" style={{ color: '#8e8e93', textDecoration: 'none', display: 'block' }}>
                  Doklady
                </Link>
              </Nav.Item>
              <Nav.Item componentClass="span" eventKey="1" icon={<Icon icon="file-text-o" />}>
                <Link to="/faktury" style={{ color: '#8e8e93', textDecoration: 'none', display: 'block' }}>
                  Faktury
                </Link>
              </Nav.Item>
              <Dropdown eventKey="2" title="Faktury vydané" icon={<Icon icon="file-text-o" />}>
                <Dropdown.Item eventKey="2-1">Řádné</Dropdown.Item>
                <Dropdown.Item eventKey="2-2">Zálohové</Dropdown.Item>
                <Dropdown.Item eventKey="2-3">ODD</Dropdown.Item>
                <Dropdown.Item eventKey="2-4">Daňové Doklady</Dropdown.Item>
              </Dropdown>
              <Dropdown eventKey="3" title="Faktury přijaté" icon={<Icon icon="file-text-o" />}>
                <Dropdown.Item eventKey="3-1">Řádné</Dropdown.Item>
                <Dropdown.Item eventKey="3-2">Zálohové</Dropdown.Item>
                <Dropdown.Item eventKey="3-3">ODD</Dropdown.Item>
                <Dropdown.Item eventKey="3-4">Daňové Doklady</Dropdown.Item>
              </Dropdown>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Platby kartou
                </Nav.Item>
              <Dropdown
                eventKey="4"
                title="Pokladní doklady"
                icon={<Icon icon="file-text-o" />}
              >
                <Dropdown.Item eventKey="4-1">Pokladna příjem</Dropdown.Item>
                <Dropdown.Item eventKey="4-2">Pokladna výdej</Dropdown.Item>
              </Dropdown>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Bankovní výpisy
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Ostatní závazky
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Ostatní pohledávky
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Interní doklady
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Přiznání
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Smlouvy
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="file-text-o" />}>
                Ostatní
                </Nav.Item>
              <Nav.Item eventKey="1" icon={<Icon icon="plus" />}>
                Přidat složku
                </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    </StyledSidebar>
  );
};

export { Sidebar };
