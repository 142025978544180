import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
`;

export const DashboardInner = styled.div`
  flex: 1;
  padding: 20px 30px;
  background: #F4F4F4;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Filter = styled.div`
  margin: 30px 0 0;
`;

export const FilterTitle = styled.div`
  color: #212121;
  font-size: 28px;
  font-weight: 700;
`;

export const FilterTable = styled.div`
  margin: 30px 0 0;
`;
