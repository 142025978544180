import React from 'react'
import { StyledContainer } from './styled'

/**
 * 404 - Page Not Found.
 * @returns {*}
 */
const NotFound = () => (
  <StyledContainer>404 - Not Found</StyledContainer>
)

export default NotFound
