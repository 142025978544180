import React, { useEffect, useState } from 'react';
import { mock } from './data';
import { Icon, Button, Table } from 'rsuite';
import styled from 'styled-components';
import { Sidebar } from '../../components/Sidebar';
import { StyledContainer, DashboardInner, ActionBar, Filter, FilterTitle, FilterTable } from './styled';
import { Link } from 'react-router-dom';

const SButton = styled(Button)`
  background: linear-gradient(135deg, #ee2e74, #f99f35);

  &:active {
    background: linear-gradient(135deg, #ee2e74, #f99f35);
  }
`;

const { Column, HeaderCell, Cell } = Table;

class SortTable extends React.Component {
  constructor(props) {
    super(props);
    const data = mock.filter((v, i) => i < 20);
    this.state = {
      addColumn: false,
      data
    };
    this.handleSortColumn = this.handleSortColumn.bind(this);
  }

  getData() {
    const { data, sortColumn, sortType } = this.state;

    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }

  render() {

    let idx = '';

    return (
      <div>
        <Table
          height={700}
          data={this.getData()}
          sortColumn={this.state.sortColumn}
          sortType={this.state.sortType}
          onSortColumn={this.handleSortColumn}
          loading={this.state.loading}
          onRowClick={data => {
            idx = data.id
          }}
        >
          <Column width={100} align="center" fixed sortable>
            <HeaderCell>Číslo</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column width={100} fixed sortable>
            <HeaderCell>Typ</HeaderCell>
            <Cell dataKey="typ" />
          </Column>

          <Column width={120} sortable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column width={200} sortable>
            <HeaderCell>Dodavatel</HeaderCell>
            <Cell dataKey="street">
              <Link to={`/faktury/faktury-vydane/2010009`}>Firma A s.r.o</Link>
            </Cell>
          </Column>

          <Column width={100}>
            <HeaderCell>Částka</HeaderCell>
            <Cell dataKey="amount" />
          </Column>

          <Column width={100}>
            <HeaderCell>Datum</HeaderCell>
            <Cell dataKey="datum" />
          </Column>

          <Column width={100}>
            <HeaderCell>Číslo ZER</HeaderCell>
            <Cell dataKey="zer" />
          </Column>
        </Table>
      </div>
    );
  }
}

const Faktury = () => {

  return (
    <StyledContainer>
      <Sidebar />
      <DashboardInner>
        <ActionBar>
          <SButton appearance="primary"><Icon icon="plus" /> Přidat fakturu</SButton>
        </ActionBar>
        <Filter>
          <FilterTitle>Faktury</FilterTitle>
          <FilterTable>
            <SortTable />
          </FilterTable>
        </Filter>
      </DashboardInner>
    </StyledContainer >
  )
}

export { Faktury }
