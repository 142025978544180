import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
`;

export const DashboardInner = styled.div`
  flex: 1;
  padding: 20px 30px;
  background: #F4F4F4;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Filter = styled.div`
`;

export const FilterTitle = styled.div`
  color: #212121;
  font-size: 28px;
  font-weight: 700;
`;

export const FilterTable = styled.div`
`;

export const Id = styled.div`
  font-size: 16px;
  padding: 10px;
  text-align: center;
  border: 1px solid #ececec;
`;

export const Number = styled.div`
  font-size: 18px;
`;

export const Status = styled.div`
  font-size: 18px;
`;

export const Detail = styled.div`
  /* border: 1px solid #8e8e93; */
  padding: 30px;
  margin: 50px 0 0;
`;

export const Date = styled.div`
  font-size: 18px;
`;

export const FakturaNumber = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const Typography = styled.div`
  font-size: ${({ size }) => size || '16px'};
  font-weight: ${({ weight }) => weight || '400'};
`;
