import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { Routes } from './routes';
import { Header } from './components/Header';
import 'rsuite/dist/styles/rsuite-default.css';
import './components/Normalize/style.css';

/**
 * Marwin Single Page Application.
 * @returns {*}
 */
const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Header />
      <Routes />
    </BrowserRouter>
  </Provider>
);

export { App };
