import { BrowserRouter, Link } from 'react-router-dom';
import {
  StyledHeader,
  HeaderInner,
  LogoWrapper,
  Search,
  Nav,
  Ul,
  Li,
  A,
} from './styled';
import { ReactComponent as Logo } from './icons/logo.svg';
import { ReactComponent as Lang } from './icons/icon-language.svg';
import { ReactComponent as Add } from './icons/icon-plus.svg';
import { ReactComponent as Alert } from './icons/icon-alert.svg';
import { ReactComponent as Notification } from './icons/icon-notification.svg';
import avatar from './icons/user.png';

const Header = () => {
  return (
    <StyledHeader>
      <BrowserRouter>
        <Link to="/" style={{ height: '100%' }}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>
        <HeaderInner>
          <Search placeholder='Hledat' />
          <Nav>
            <Ul>
              <Li>
                <A to='/'>
                  <Lang />
                </A>
              </Li>
              <Li>
                <A to='/'>
                  <Add />
                </A>
              </Li>
              <Li>
                <A to='/'>
                  <Alert />
                </A>
              </Li>
              <Li>
                <A to='/'>
                  <Notification />
                </A>
              </Li>
              <Li>
                <A to='/'>
                  <img src={avatar} />
                </A>
              </Li>
            </Ul>
          </Nav>
        </HeaderInner>
      </BrowserRouter>
    </StyledHeader>
  );
};

export { Header };
