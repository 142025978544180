import styled from 'styled-components';
import { NavLink } from "react-router-dom";

export const StyledHeader = styled.header`
  height: 58px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E8E8E8;
`;

export const HeaderInner = styled.div`
  padding: 0 30px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  height: 100%;
  width: 250px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-right: 1px solid #E8E8E8;
`;

export const Search = styled.input`
  width: 300px;
  height: 40px;
  color: #25262D;
  padding: 0 12px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #E8E8E8;

  &::placeholder {
    color: #25262D;
    opacity: .25;
  }
`;

export const Nav = styled.nav`

`;

export const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Li = styled.li`
  margin-right: 30px;

  &::last-child {
    margin-right: 0;
  }
`;

export const A = styled(NavLink)`
  
  img {
    display: block;
    max-width: 40px;
    max-height: 40px;
  }
`;
