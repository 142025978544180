import styled from 'styled-components';

export const StyledSidebar = styled.div`
  width: 250px;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  border-right: 1px solid #E8E8E8;
`;

export const Folder = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

export const FolderName = styled.div`
  color: #B7B7B7;
  font-size: 11px;
  padding: 10px 0;
  text-transform: uppercase;
`;


