import * as ROUTE from '../constants';
import { Dashboard } from '../features/Dashboard';
import { Doklady } from '../features/Doklady';
import { Faktury } from '../features/Faktury';
import { FakturaDetail } from '../features/Faktury/components/FakturaDetail';

const routes = [
  {
    id: 1,
    path: ROUTE.DASHBOARD,
    component: Dashboard,
  },
  {
    id: 2,
    path: ROUTE.DOKLADY,
    component: Doklady,
  },
  {
    id: 3,
    path: ROUTE.FAKTURY,
    component: Faktury,
  },
  {
    id: 4,
    path: ROUTE.FAKTURY_VYDANE_DETAIL,
    component: FakturaDetail,
  },
];

export { routes };
