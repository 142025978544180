import { StrictMode } from 'react';
import { render } from 'react-dom';
import { App } from './App';

/**
 * ReactDOM render.
 * @param {component} react component
 * @param {element} DOM element
 * @returns {App}
 */
render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
