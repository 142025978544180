import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import NotFound from '../features/NotFound';

/**
 * Routes.
 * @returns {*}
 */

const Routes = () => (
  <Switch>
    {routes.map(({ id, path, component }) => (
      <Route
        key={id}
        path={path}
        exact={true}
        component={component}
      />
    ))}
    <NotFound />
  </Switch>
);

export { Routes };
