import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col, Panel, List, Badge, Divider } from 'rsuite';
import { Sidebar } from '../../components/Sidebar';
import { StyledContainer, DashboardInner } from './styled';

const data = [{
  id: 1,
  title: 'Nový',
  count: 13,
}, {
  id: 2,
  title: 'K dořešení',
  count: 47,
}, {
  id: 3,
  title: 'Zpracováno',
  count: 71,
}, {
  id: 4,
  title: 'Archivováno',
  count: 1999,
}];

const Dashboard = () => {
  return (
    <StyledContainer>
      <Sidebar />
      <DashboardInner>
        <Grid fluid>
          <Row>
            <Col xs={24} sm={24} md={8}>
              <Panel header="Task list">
                <List bordered>
                  {data.map(({ id, title, count }) => (
                    <List.Item key={id} index={id}>
                      {title} <Badge content={count} />
                    </List.Item>
                  ))}
                </List>
              </Panel>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Panel header="Komunikace">
                <List bordered>
                  <List.Item index="1">
                    Komentařů k dolkladu <Badge content="8" />
                  </List.Item>
                </List>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={8}>
              <Panel header="Výučtovaní za zaměstnance">
                <List bordered>
                  <List.Item index="1">
                    Pavel Nový -1000 Kč <Badge content="1" />
                  </List.Item>
                </List>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </DashboardInner>
    </StyledContainer>
  );
};

export { Dashboard };
