import React from 'react';
import { Icon, Button, List } from 'rsuite';
import styled from 'styled-components';
import { Sidebar } from '../../components/Sidebar';
import { StyledContainer, DashboardInner, ActionBar, Filter, FilterTitle, FilterTable } from './styled';

const SButton = styled(Button)`
  background: linear-gradient(135deg, #ee2e74, #f99f35);

  &:active {
    background: linear-gradient(135deg, #ee2e74, #f99f35);
  }
`;

const data = ['Doklad 1', 'Doklad 2', 'Doklad 3', 'Doklad 4'];

const Doklady = () => {
  return (
    <StyledContainer>
      <Sidebar />
      <DashboardInner>
        <ActionBar>
          <SButton appearance="primary"><Icon icon="plus" /> Přidat doklad</SButton>
        </ActionBar>
        <Filter>
          <FilterTitle>Doklady</FilterTitle>
          <FilterTable>
            <List hover>
              {data.map((item, index) => (
                <List.Item key={index} index={index} style={{ padding: '15px' }}>
                  {item}
                </List.Item>
              ))}
            </List>
          </FilterTable>
        </Filter>
      </DashboardInner>
    </StyledContainer>
  )
}

export { Doklady }
